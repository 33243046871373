import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { About } from "../pages/About/About"
import { Home } from "../pages/Home/Home"
import { SplashPage } from "../pages/SplashPage/SplashPage"

const AppRoutes = () => {
  const routes = [
    {
      route: 'Home',
      path: '/home',
      element: <Home />
    },
    {
      route: 'Home',
      path: '/about',
      element: <About />
    },
    {
      route: 'Splash',
      path: '/',
      element: <SplashPage />
    }
  ]

  return (
    <BrowserRouter>
      {/* <Header />
      <div style={{ 'position': 'relative', 'flex': '1'}}>
        <SideNav />
        &nbsp;
      </div> */}
      <Routes>
        {routes.map(route => {
          return (
            <Route
              path={route.path}
              element={route.element} />
          )
        })}
        <Route path="*" element={<Navigate to="/error" />} />
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }