import { AppRoutes } from "./routes/app.routes";
import './config/firebase.config';


function App() {

  return (
    <>
      <AppRoutes />
    </>
  );
}

export default App;

