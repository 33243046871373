import { addDoc, collection } from 'firebase/firestore';
import { db } from './../../config/firebase.config'
import React, { useState } from 'react';

const SplashPage = () => {
  const [saving, setSaving] = useState(false);
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [name, setName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const img = require('./../../assets/images/idverde.png')

  const handleEmailChange = (event: any) => {
    if (errorMessage !== '' && isValidEmail(email)) setErrorMessage('')
    setEmail(event.target.value);
    setErrorMessage('')
  };

  const handleNameChange = (event: any) => {
    setName(event.target.value);
  };

  const isValidName = (name: string) => {
    return name && name.length > 3
  }

  const isValidEmail = (email: any) => {
    if (!email) return false

    // Validação básica de email (pode ser aprimorada conforme suas necessidades)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (event: any) => {
    if (saving) return
    
    event.preventDefault();

    // Validar o email
    if (!isValidName(name) || !isValidEmail(email)) {
      setErrorMessage('Preencha os campos corretamente.');
      return;
    }

    setSaving(true)

    try {
      const docRef = await addDoc(collection(db, "leads"), {
        name: name,
        email: email
      });
      setSuccess(true)
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    } finally {
      setSaving(false)
    }

    // Fazer a requisição genérica (substitua a URL pela sua)
    // fetch('https://sua-api.com/cadastrar-email', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({ email }),
    // })
    //   .then((response) => {
    //     if (response.ok) {
    //       // Se a requisição foi bem sucedida, faça algo (ex: redirecionar para outra página)
    //     } else {
    //       // Se a requisição falhou, trate o erro adequadamente
    //       throw new Error('Falha ao cadastrar o email.');
    //     }
    //   })
    //   .catch((error) => {
    //     setErrorMessage(error.message);
    //   });
  };

  return (
    <div className={'container'}>
      {/* Logo da empresa */}
      <img src={img} alt="IDVerde" style={{width: '70%'}} />

      {/* Descrição */}
      <p className={'description-text'}>
        <strong>Identidade digital</strong> para pacientes de <strong>cannabis medicinal</strong><br/><br/>
        Todos os seus documentos em um único lugar, para <strong>transportar</strong> e <strong>comprar</strong> sua medicação com facilidade e segurança.
      </p>
    
      {/* Formulário */}
      {!success && (
        <form
          onSubmit={handleSubmit}
          className={'form-wrapper'}>

          {/* Campo de Nome */}
          <input
            className={`text-input ${name.length < 3 && errorMessage ? 'error' : ''}`}
            type="text"
            placeholder="Nome Completo"
            value={name}
            onChange={handleNameChange}
          />

          {/* Campo de email */}
          <input
            className={`text-input ${errorMessage ? 'error' : ''}`}
            type="email"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
          />

          {/* Botão de envio */}
          <button disabled={errorMessage !== '' || saving} type="submit" className={'cta-button'}>Quero conhecer</button>

          {/* Mensagem de erro */}
          {errorMessage && <p className={'error-message'}>{errorMessage}</p>}
        </form>
      )}
      {success && (
        <div className={'success-wrapper'}>
          <p><strong>Cadastro realizado com sucesso.</strong></p>
          <p>
            Falta pouco para ter sua identidade digital canábica na palma da sua mão!<br/>
           Você receberá um e-mail assim que o App IDVerde estiver disponível para uso.</p>
        </div>
      )}
    </div>
  );
};

export { SplashPage };